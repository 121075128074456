import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import highPerformanceNetworksImage from './images/high-performance-networks.svg';
import LinuxFoundation from '@images/logos-partners/Linux-Foundation-logo.svg';

import {
  go,
  python,
  pytest,
  ansible,
  terraform,
  puppet,
  saltStack,
  bamboo,
  jenkins,
  spinnaker,
  huawei,
  zuul,
  vault,
  vagrant,
  helm,
  juju,
  groovy,
  git,
  fluentd,
  prometheus,
  grafana,
  elkStack,
  aws,
  redHat,
  openStack,
  kubernetes,
  docker,
  ceph,
  envoy,
  istio,
  kvm,
  xen,
  etcd,
  p4,
  sonic,
  f5,
  nokiaNetworks,
  checkPoint,
  paloAltoNetworks,
  openVSwitch,
  mongoDB,
  openShift,
  ibmCloud,
  googleCloud,
  gitLab,
  circleCI,
  cPlusPlus,
  postgreSQL,
  mariaDB,
  oracleCloudInfrastructure,
  azure,
  scapy,
  robotFramework,
  tRex,
  travisCI,
  vpp,
  tungstenFabric,
  cisco,
  juniperNetworks,
  aristaNetworks,
  dell,
  vmwareESXi,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Network Solution Services - CodiLime Company',
  description:
    "Maximize your network's potential with CodiLime's expert help. We optimize virtualized, cloud, and all types of networks. Gain a competitive edge with us.",
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Network solution
      <br />
      services
    </>
  ),
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
    titleAndBorderContainer: styles.headerTitleAndBorderContainer,
    innerWrapper: styles.headerInnerWrapper,
  },
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    id: 'our-approach',
    title: 'How we work',
  },
  imgAlt: 'service models for network solutions',
  classNames: {
    section: styles.sliderWithClickableBlocks,
    innerWrapper: styles.sliderWithClickableBlocksInnerWrapper,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Technology stack',
    subtitle: (
      <>
        We work with the best available technologies and frameworks that allow us to provide excellent results to our
        clients. Our large technology stack helps us adapt to your integral infrastructure and pick the best tools to
        solve your problems.
      </>
    ),
    classNames: {
      innerWrapper: styles.techStackInnerWrapper,
      titlesContainer: styles.techStackTitlesContainer,
      subtitleStyles: styles.techStackSubtitleStyles,
      titleStyles: styles.techStackTitleStyles,
    },
  },
  classNames: {
    containerWithTwoColumns: styles.techStackContainerWithTwoColumns,
    singleColumn: styles.techStackSingleColumn,
    singleCategory: styles.techStackSingleCategory,
    categoryTitle: styles.techStackCategoryTitle,
    iconsForCategory: styles.techStackIconsForCategory,
    rectangleStyles: styles.techStackRectangleStyles,
  },
  hasButtonLoadMore: true,
  blocksForFirstColumn: [
    {
      categoryTitle: 'Open-source networking',
      technologiesData: [p4, sonic, openVSwitch, vpp, tungstenFabric],
    },
    {
      categoryTitle: 'Vendor solutions',
      technologiesData: [
        cisco,
        paloAltoNetworks,
        juniperNetworks,
        dell,
        aristaNetworks,
        huawei,
        f5,
        checkPoint,
        nokiaNetworks,
      ],
    },
    {
      categoryTitle: 'Virtualization',
      technologiesData: [kvm, xen, vmwareESXi],
    },
    {
      categoryTitle: 'Network testing',
      technologiesData: [tRex, robotFramework, scapy],
    },
    {
      categoryTitle: 'Private cloud',
      technologiesData: [redHat, openStack],
    },
    {
      categoryTitle: 'Public cloud',
      technologiesData: [googleCloud, aws, azure, ibmCloud, oracleCloudInfrastructure],
    },
    {
      categoryTitle: 'Backend',
      technologiesData: [go, cPlusPlus, python, pytest],
    },
    {
      categoryTitle: 'Automation',
      technologiesData: [ansible, puppet, saltStack],
    },
    {
      categoryTitle: 'CI/CD',
      technologiesData: [circleCI, travisCI, bamboo, jenkins, zuul, spinnaker, gitLab],
    },
    {
      categoryTitle: 'IaC',
      technologiesData: [terraform, vault, vagrant, helm, juju, groovy, git],
    },
    {
      categoryTitle: 'Monitoring',
      technologiesData: [fluentd, prometheus, grafana, elkStack],
    },
    {
      categoryTitle: 'Cloud-native platforms and services',
      technologiesData: [kubernetes, openShift, docker, envoy, istio],
    },
    {
      categoryTitle: 'Storage / database',
      technologiesData: [ceph, etcd, mariaDB, postgreSQL, mongoDB],
    },
  ],
};

export const sectionUnderHeaderProps = {
  //contactButtonLabel: 'Transform your network',
  contactButtonLabel: 'Let’s talk networks', // CRO test
  textPartOne:
    'A well-functioning, modern network is the backbone of every organization striving for growth and development. Virtualization, increased cloud use and a constant increase in traffic mean a need for constant network optimization. This, however, requires great network expertise. At CodiLime, we have the skills and knowledge to uncover the potential of your network.',
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageCustomSection,
      outerWrapper: styles.contactButtonImageSectionOuterWrapper,
    },
  },
  title: 'High-performance networks at your fingertips',
  imageAlt: 'High-performance networks',
  isH2: true,
  buttonTitle: 'Transform your network',
  image: highPerformanceNetworksImage,
  classNames: {
    customContent: styles.contactButtonImageSectionContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionCustomContainer,
    customButton: styles.contactButtonImageSectionButton,
  },
};

export const opacityScrolledSliderProps = {
  title: 'How can network services benefit your business?',
  id: 'your-benefits',
  textBlocks: [
    <>
      High-performance and resilient networks provide <span>the best quality of service</span> to your clients.
    </>,
    <>
      Advanced network solutions <span>shorten the time to market</span> of your products.
    </>,
    <>
      Automation and virtualization take you one <span>step ahead of your competitors</span>.
    </>,
    <>
      Working with CodiLime brings <span>world-class professionals</span> to your team.
    </>,
  ],
  blocksGap: 37,
  topOpacity: 0.44,
  shiftDown: 0.3,
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
  },
};

export const testimonialProps = {
  testimonial: {
    quote: `We are excited to welcome CodiLime as a new member of the Linux Foundation and LF Networking. CodiLime’s expertise in network engineering will be a valuable asset especially for LF Networking, where we focus on collaborative open-source networking solutions. We admire the contribution CodiLime has made to Tungsten Fabric, a project that has been developed under the umbrella of the Linux Foundation. This is a sign of good things to come.`,
    author: 'Arpit Joshipura - General Manager, Networking, Edge and IOT',
    logo: LinuxFoundation,
    logoAlt: 'The Linux Foundation',
  },
  classNames: {
    innerWrapper: styles.innerWrapperTestimonials,
  },
  paragraphTitleStyles: styles.testimonialTitle,
};

export const sliderWithTextBlocksProps = {
  sectionData: {
    title: 'Networks we work with',
    id: 'expertise',
    subtitle: (
      <>
        We&apos;ve been building our network expertise and gaining experience in various projects{' '}
        <span>since 2011</span>. As a result, our professionals are qualified in all types of networks, including{' '}
        <span>virtualized, SDNs and those of private and public clouds</span>. Now, as a fully developed networking
        expert, we focus on 5 domains of networking.
      </>
    ),
  },
  blocksData: [
    'Telco',
    'Data center',
    'Enterprise',
    <>
      Virtualized <br />
      <span>(incl. VNFs, clouds)</span>
    </>,
    'Security',
  ],
  classNames: {
    cardContainer: styles.sliderWithTextBlocksCardContainer,
    cardTitle: styles.sliderWithTextBlocksCardTitle,
    titlesContainer: styles.sliderWithTextBlocksTitlesContainer,
    titleH2: styles.sliderWithTextBlocksTitleH2,
    subtitleStyles: styles.sliderWithTextBlocksSubtitleStyles,
    section: styles.sliderWithTextBlocksSection,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: <>Our publications about network solutions</>,
    subtitle: (
      <>
        The world of networks is impossible to cover on just one page. Head on to our blog to read more about our
        network solutions and the technologies we use. You will find articles written by network engineers, solution
        architects, business specialists and other professionals.
      </>
    ),
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
      subtitleStyles: styles.blocksRedirectingToResourcesSubtitle,
    },
  },
  blocks: [
    {
      text: 'In-band network telemetry - what is it all about?',
      link: '/blog/in-band-network-telemetry-what-is-it-all-about/',
    },
    {
      text: 'What is policy based routing?',
      link: '/blog/pbr-policy-based-routing/',
    },
    {
      text: 'Linux network troubleshooting - commands and examples',
      link: '/blog/linux-network-troubleshooting/',
    },
    {
      text: 'Introduction to network telemetry',
      link: '/blog/network-telemetry/',
    },
    {
      text: 'How to drop a packet in Linux in more ways than one?',
      link: '/blog/how-to-drop-a-packet-in-linux-in-more-ways-than-one/',
    },
    {
      text: 'Developing custom network functionality using SONiC',
      link: '/blog/developing-custom-network-functionality-sonic/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'See our other services',
  subtitle:
    'CodiLime gathers a diverse set of talents. Besides network services, we can do much more for your project. We provide support throughout the entire software development lifecycle.',
  blocks: [
    {
      text: (
        <>
          <span>Network professional </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Test automation </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.TEST_AUTOMATION,
    },
    {
      text: (
        <>
          <span>DevOps services</span>
        </>
      ),
      link: paths.SERVICES.DEVOPS,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksWithRedirectsInnerWrapper,
    title: styles.coloredBlocksWithRedirectsTitle,
    rightPart: styles.coloredBlocksWithRedirectsRightPart,
  },
};

export const blocksInRowsWithOneActiveProps = {
  title: 'Our network services',
  subtitle: (
    <>
      Our large talent pool of top experts allows us to provide a wide range of network services. From{' '}
      <b>designing a new solution </b>
      through <b>deploying it to operational maintenance</b> where needed - we do it all. We also provide team support
      and training to leave our clients with gained knowledge.
    </>
  ),
  blocks: [
    {
      title: 'Network design',
      description: (
        <>
          We design network infrastructure that corresponds to your business needs. Our goal is to provide a topology
          that makes the best use of your resources. That means we design a network infrastructure that is{' '}
          <b>resilient, secure, scalable, and easy to adapt</b> to future technologies. We also take care of network
          protection and data security.
        </>
      ),
    },
    {
      title: 'Network virtualization & cloud migration',
      description: (
        <>
          Software defined networking is becoming the standard. Our team is here to help you with moving your
          infrastructure into the cloud and providing virtualized network functions. We work on both{' '}
          <b>private and public clouds</b> and have expertise in traditional and virtual networks, making the transition
          as smooth as possible.
        </>
      ),
    },
    {
      title: 'Network automation',
      description: (
        <>
          Rapid development of networking technologies brings the need for fast, cost-efficient, and error-proof network
          operations. This is where network automation comes in handy. Our teams can provide you with{' '}
          <b>reusable, scalable network solutions</b> that make your management and daily operations efficient.
        </>
      ),
    },
    {
      title: 'Network testing',
      description: (
        <>
          Testing in an efficient and automated way can significantly{' '}
          <b>reduce your time to market and cost of operations</b>. We design and automate tests for performance,
          scalability, functionality, stress and interoperability. Our team can also build and deploy Proof of Concept
          and MVP.
        </>
      ),
    },
    {
      title: 'Network management',
      description: (
        <>
          Our goal is to deliver high-performance, resilient network solutions. We do it not only through one-time
          projects but also through long-running partnerships. We provide support with network operations, including{' '}
          <b>monitoring and management of the network environment</b>.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActive,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    outerWrapper: styles.blocksInRowsWithOneActiveOuterWrapper,
    titlesContainer: styles.blocksInRowsWithOneActiveTitlesContainer,
    blocksContainer: styles.blocksInRowsWithOneActiveBlocksContainer,
  },
};

export const bannerWithImageProps = {
  title: 'Kubernetes application networking: maximize its potential',
  extraTitleOnDesktop: ' with Ingress, service mesh, Gateway API',
  bannerCategory: 'Kubernetes',
};
