// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActive = "payload-module--blocks-in-rows-with-one-active--ef070";
export var blocksInRowsWithOneActiveBlocksContainer = "payload-module--blocks-in-rows-with-one-active-blocks-container--3a19d";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--0c4d0";
export var blocksInRowsWithOneActiveOuterWrapper = "payload-module--blocks-in-rows-with-one-active-outer-wrapper--46e80";
export var blocksInRowsWithOneActiveTitlesContainer = "payload-module--blocks-in-rows-with-one-active-titles-container--b91d1";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--c18e4";
export var blocksRedirectingToResourcesSubtitle = "payload-module--blocks-redirecting-to-resources-subtitle--8813e";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--33eb2";
export var coloredBlocksWithRedirectsInnerWrapper = "payload-module--colored-blocks-with-redirects-inner-wrapper--141a1";
export var coloredBlocksWithRedirectsRightPart = "payload-module--colored-blocks-with-redirects-right-part--5b4db";
export var coloredBlocksWithRedirectsTitle = "payload-module--colored-blocks-with-redirects-title--36681";
export var contactButtonImageCustomSection = "payload-module--contact-button-image-custom-section--0538f";
export var contactButtonImageSectionButton = "payload-module--contact-button-image-section-button--5cf64";
export var contactButtonImageSectionContent = "payload-module--contact-button-image-section-content--19e82";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--f0ddd";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--82417";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--925c0";
export var contactButtonImageSectionOuterWrapper = "payload-module--contact-button-image-section-outer-wrapper--5219e";
export var header = "payload-module--header--429ca";
export var headerBorder = "payload-module--header-border--d164c";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--9b5e1";
export var headerTitleAndBorderContainer = "payload-module--header-title-and-border-container--69879";
export var headerTitleContainer = "payload-module--header-title-container--8cd34";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--c1483";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--ed6f1";
export var sliderWithClickableBlocks = "payload-module--slider-with-clickable-blocks--69784";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--227f3";
export var sliderWithTextBlocksCardContainer = "payload-module--slider-with-text-blocks-card-container--feeba";
export var sliderWithTextBlocksCardTitle = "payload-module--slider-with-text-blocks-card-title--3ab39";
export var sliderWithTextBlocksSection = "payload-module--slider-with-text-blocks-section--5030e";
export var sliderWithTextBlocksSubtitleStyles = "payload-module--slider-with-text-blocks-subtitle-styles--ed67a";
export var sliderWithTextBlocksTitleH2 = "payload-module--slider-with-text-blocks-title-h2--11337";
export var sliderWithTextBlocksTitlesContainer = "payload-module--slider-with-text-blocks-titles-container--ecb51";
export var techStackCategoryTitle = "payload-module--tech-stack-category-title--bdb95";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--13b18";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--7fe87";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--c6f6c";
export var techStackRectangleStyles = "payload-module--tech-stack-rectangle-styles--60204";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--e0da4";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--df17e";
export var techStackSubtitleStyles = "payload-module--tech-stack-subtitle-styles--c3109";
export var techStackTitleStyles = "payload-module--tech-stack-title-styles--832c8";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--0164a";
export var testimonialTitle = "payload-module--testimonial-title--f7768";